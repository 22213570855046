import React, { useEffect, useRef, useState } from "react";
import { BiMenuAltRight } from "react-icons/bi";
import GooglePlay from "../img/nav-logo.png";
import { RiseOutlined } from "@ant-design/icons";
import { Link, Navigate, useNavigate } from "react-router-dom";

import { Row, Col, Select } from "antd";
import "../Styles/Navbar.css";
import Footer from "./Footer";

const SearchPage = ({ setQuery, query, setType }) => {


const navigate=useNavigate();


  const gotoLogin=()=>{
    // console.log("login page")
    sessionStorage.setItem("starter", true);
    navigate("/login")
  }

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          margin: "1.6rem",
          alignItems: "center",
        }}
      >
        <img
          className="hide-formulaw"
          src={GooglePlay}
          width={"200px"}
          height={"52px"}
        />
        {/* <ul // Use motion.ul instead of ul
          className="menu"
        >
          <li>
            <Link className="btn1" to="/search-history">
              History
            </Link>
          </li>
          <li>
            <Link className="btn2" to="/">
              logout
            </Link>
          </li>
        </ul> */}
        <div
          className="menuIcon"
          
        >
          <BiMenuAltRight size={30} />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "5rem",
          marginBottom: "3rem",
        }}
      >
        <img
          className="formulaw-icon"
          src={GooglePlay}
          width={"200px"}
          height={"52px"}
        />
      </div>
      <Row gutter={[18]} style={{ justifyContent: "center", margin: 0 }}>
        <Col lg={10} md={10} xs={24} sm={24}>
          <Select 
            onClick={gotoLogin}          
            style={{
              width: "100%",              
            }}
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            placeholder = "Enter your query, be as detailed as possible"       
          />
        </Col>
        <Col lg={2} md={5} xs={24} sm={24}>
          <button
            type="button"
            className="btn3"
          >
            Submit
          </button>
        </Col>
      </Row>
      <Row
        gutter={[18]}
        style={{ justifyContent: "center", margin: 0, paddingTop: "2.5rem" }}
      >
        <Col lg={6} md={6} xs={24} sm={24}>
          {/* <p className="link">
            My passport is stolen <RiseOutlined className="up-icon" />{" "}
          </p> */}

         
        </Col>
      </Row>
      <Footer />
    </>
  );
};

export default SearchPage;


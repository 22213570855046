import React, { useRef, useState } from "react";
import { BiMenuAltRight } from "react-icons/bi";
import GooglePlay from "../img/nav-logo.png";
import Union from "../img/Union.png";
import { Link } from "react-router-dom";
import { Input, Row, Col } from "antd";
import "../Styles/Navbar.css";
import Footer from "./Footer";
const Navbar = () => {
  const menuRef = useRef(null);
  const [menuOpened, setMenuOpened] = useState(false);

  const getMenuStyles = (menuOpened) => {
    if (document.documentElement.clientWidth <= 770) {
      console.log("outside of sidebar reached");

      console.log("menuOpened :", menuOpened);
      return { right: !menuOpened && "-100%" };
    }
  };
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          margin: "1.6rem",
          alignItems: "center",
        }}
      >
        <img src={GooglePlay} width={"200px"} height={"52px"} />
        <ul // Use motion.ul instead of ul
          className="menu"
          ref={menuRef}
          style={getMenuStyles(menuOpened)}
        >
          <li>
            <Link className="btn1" to="/">
              Log in
            </Link>
          </li>
          <li>
            <Link className="btn2" to="/">
              Sign up
            </Link>
          </li>
        </ul>
        <div
          className="menuIcon"
          onClick={() => setMenuOpened((prev) => !prev)}
        >
          <BiMenuAltRight size={30} />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "2rem",
          marginBottom: "5rem",
        }}
      >
        <img src={Union} className="middle-img"  />
      </div>
      <Row gutter={[18]} style={{ justifyContent: "center", margin: 0 }}>
        <Col lg={12} md={12} xs={24} sm={24}>
          <Input
            className="search-item"
            placeholder="Document for registering a company"
          />
        </Col>
        <Col lg={2} md={5} xs={24} sm={24}>
          <button className="btn3">Submit</button>
        </Col>
      </Row>
      <Footer/>
    </>
  );
};

export default Navbar;

import React, { useEffect, useRef, useState } from "react";
import { BiMenuAltRight } from "react-icons/bi";
import { FaUserAlt } from "react-icons/fa";
import GooglePlay from "../img/nav-logo.png";
import Avatar from "../img/AI Avatar.png";
import { Link } from "react-router-dom";
import { useParams, useNavigate } from "react-router-dom";
import { Input, Row, Col, Card, Spin, message, Tooltip } from "antd";

import {
  CopyOutlined,
  LikeOutlined,
  DislikeOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";

import { API_BASE_URL } from "../config/config";
import { APIUrlList } from "../functional/api_url";
import commonFunction from "../functional/common_function";

import "../Styles/SearchResult.css";
import Footer from "./Footer";
const SearchResults = ({ setQuery, query }) => {
  
  const menuRef = useRef(null);
  const { Search } = Input;

  const [menuOpened, setMenuOpened] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  let { question } = useParams();
  const navigate = useNavigate();

  const [demoData, setDemoData] = useState(question);

  let type = localStorage.getItem("type");


  const [data, setData] = useState([]);

  const [inputData, setInputData] = useState();

  const [showSpinner, setShowSpinner] = useState(false);

  const [likeColor1, setLikeColor1] = useState("#BDBDBD");
  const [dislikeColor1, setDislikeColor1] = useState("#BDBDBD");

  const [likeColor2, setLikeColor2] = useState("#BDBDBD");
  const [dislikeColor2, setDislikeColor2] = useState("#BDBDBD");

  const [showReGenerateRes, setShowReGenerateRes] = useState(false);
  // const [hidebtn,setHideBtn]=useState(false);

  const [regerateData, setRegenerate] = useState([]);

  const [consultbtn,setconsultBtn]=useState(false);

  const handleLikeClick1 = (value) => {
    setShowReGenerateRes(value);
    setLikeColor1("#2196F3");
    setDislikeColor1("#BDBDBD");
  };

  const handleDislikeClick1 = (value) => {
    setShowReGenerateRes(value);
    setDislikeColor1("#2196F3");
    setLikeColor1("#BDBDBD");
  };

  const handleLikeClick2 = () => {
    setLikeColor2("#2196F3");
    setDislikeColor2("#BDBDBD");
  };

  const handleDislikeClick2 = () => {
    setDislikeColor2("#2196F3");
    setLikeColor2("#BDBDBD");
  };

  const goToLoginPage = () => {
    localStorage.clear()
  };

  const success = () => {
    messageApi.open({
      type: "Success",
      content: "Answer Copied",
      icon: <CheckCircleOutlined className="green-icon" />,
      // style: customStyles
    });
  };
  const error = () => {
    messageApi.open({
      type: "error",
      content: "Answer is not copied",
    });
  };

  const errorSearch = () => {
    messageApi.open({
      type: 'error',
      content: 'Please enter valid text',
    });
  };

  async function copyTextToClipboard(text) {
    if ("clipboard" in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand("copy", true, text);
    }
  }

  const handleCopyClick = (data) => {
    // Asynchronously call copyTextToClipboard
    
    copyTextToClipboard(`${data[0].answer}`)
      .then(() => {
        success();
      })
      .catch((err) => {
        error();
      });
  };

  // const { selectQuery, type } = location.state;

  const getMenuStyles = (menuOpened) => {
    if (document.documentElement.clientWidth <= 770) {
      return {
        right: !menuOpened && "-100%",
        display: !menuOpened ? "none" : null,
      };
    }
  };

  function handleSuffixClick() {


    if(query.length==0){

      errorSearch();
    }else{
      getSearchResultEdit();
    }
   
  
  }

  function handleInputChange(event) {
    const inputValue = event.target.value;
   
    setQuery(inputValue);
    setDemoData(inputValue);
  }


  useEffect(()=>{

    console.log('demoData :', demoData);

    console.log(' query:',query );
    if (demoData !== '') {
      navigate(`/nav-result/${encodeURIComponent(demoData)}`, { replace: true });
    } else {
    }
  },[demoData])

  const getSearchResult = () => {
    // fetched data from localstoarage
    setShowSpinner(true);
    let getAuthToken = JSON.parse(localStorage.getItem("accessToken"));
    let userInfo = JSON.parse(localStorage.getItem("userInfo"));

    const url = API_BASE_URL + APIUrlList.ChatGpt;
    const config = {
      headers: { Authorization: `Bearer ${getAuthToken}` },
    };
    let payload = {
      type: type,
      query: question,
      phone_number: userInfo.phoneNo,
      appType: "web",
    };

    commonFunction
      ._sendAPIRequest(payload, url, "post", config)
      .then((response) => {
        setData(response.data.data);
        setconsultBtn(true)
        setShowSpinner(false);
      })
      .catch((error) => {
        commonFunction._showAlertError(error?.response?.data?.message);
        setShowSpinner(false);
      });
  };
  const getSearchResultEdit = () => {
    //fetched data from localstoarage
    setShowSpinner(true);
    let getAuthToken = JSON.parse(localStorage.getItem("accessToken"));
    let userInfo = JSON.parse(localStorage.getItem("userInfo"));

    const url = API_BASE_URL + APIUrlList.ChatGpt;
    const config = {
      headers: { Authorization: `Bearer ${getAuthToken}` },
    };
    let payload = {
      // type: type == "btn" ? "AI" : type == "select" ? "Search" : null,
      type: "AI",
      query: query.length == 0 ? question : query,
      phone_number: userInfo.phoneNo,
      appType: "web",
    };

    commonFunction
      ._sendAPIRequest(payload, url, "post", config)
      .then((response) => {
        setData(response.data.data);
        setRegenerate([]);
        setDislikeColor1("#BDBDBD");
        setShowSpinner(false);
      })
      .catch((error) => {
        commonFunction._showAlertError(error?.response?.data?.message);
        setShowSpinner(false);
      });
  };

  const reGenerateRes = () => {
    // fetched data from localstoarage
    setShowSpinner(true);
    let getAuthToken = JSON.parse(localStorage.getItem("accessToken"));
    let userInfo = JSON.parse(localStorage.getItem("userInfo"));

    const url = API_BASE_URL + APIUrlList.RegenrateResponse;
    const config = {
      headers: { Authorization: `Bearer ${getAuthToken}` },
    };
    let payload = {
      query: query.length == 0 ? question : query,
      phone_number: userInfo.phoneNo,
      appType: "web",
    };

    commonFunction
      ._sendAPIRequest(payload, url, "post", config)
      .then((response) => {
        setRegenerate(response.data.data);
        setShowReGenerateRes(false);
        setShowSpinner(false);
      })
      .catch((error) => {
        commonFunction._showAlertError(error?.response?.data?.message);
        setShowSpinner(false);
      });
  };

  const likeDislike = (rating) => {
    // fetched data from localstoarage
    setShowSpinner(true);
    let getAuthToken = JSON.parse(localStorage.getItem("accessToken"));
    let userInfo = JSON.parse(localStorage.getItem("userInfo"));

    const url = API_BASE_URL + APIUrlList.ChatRating;
    const config = {
      headers: { Authorization: `Bearer ${getAuthToken}` },
    };
    let payload = {
      query_id: data[0]._id,
      rating: rating,
      phone_number: userInfo.phoneNo,
    };

    commonFunction
      ._sendAPIRequest(payload, url, "post", config)
      .then((response) => {
        setShowSpinner(false);
      })
      .catch((error) => {
        commonFunction._showAlertError(error?.response?.data?.message);
        setShowSpinner(false);
      });
  };

  useEffect(() => {
    getSearchResult();
  }, []);

  return (
    <>
      {contextHolder}
      <Spin spinning={showSpinner}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            margin: "1.6rem",
            alignItems: "center",
          }}
        >
          <img src={GooglePlay} width={"200px"} height={"52px"} />
          <ul // Use motion.ul instead of ul
            className="menu"
            ref={menuRef}
            style={getMenuStyles(menuOpened)}
          >
            <li>
              {/* <Link className="btn1" to="/search-history">
                 History
            </Link> */}
            </li>
            <li>
              <Link className="btn2" to="/" onClick={goToLoginPage}>
                logout
              </Link>
            </li>
          </ul>
          <div
            className="menuIcon"
            onClick={() => setMenuOpened((prev) => !prev)}
          >
            <BiMenuAltRight size={30} />
          </div>
        </div>
        <Row
          gutter={[24]}
          style={{ justifyContent: "center", alignItems: "center", margin: 0 }}
        >
          <Col lg={1} md={1} xs={4} sm={4}>
            <FaUserAlt className="user-icon" />
          </Col>
          <Col lg={14} md={14} xs={18} sm={18}>
            {/* <Row>
              <Col lg={20} md={18} xs={24} sm={24}>
                <Input
                  className="search-result"
                  // suffix={
                  //   <Tooltip title="click  for Edit">
                  //   <svg
                  //     xmlns="http://www.w3.org/2000/svg"
                  //     width="24"
                  //     height="24"
                  //     viewBox="0 0 24 24"
                  //     fill="none"
                  //     cursor={"pointer"}
                  //     onClick={handleSuffixClick}
                  //   >
                  //     <g clip-path="url(#clip0_64_129)">
                  //       <path
                  //         d="M3 17.4601V20.5001C3 20.7801 3.22 21.0001 3.5 21.0001H6.54C6.67 21.0001 6.8 20.9501 6.89 20.8501L17.81 9.94006L14.06 6.19006L3.15 17.1001C3.05 17.2001 3 17.3201 3 17.4601ZM20.71 7.04006C21.1 6.65006 21.1 6.02006 20.71 5.63006L18.37 3.29006C17.98 2.90006 17.35 2.90006 16.96 3.29006L15.13 5.12006L18.88 8.87006L20.71 7.04006Z"
                  //         fill="#718096"
                  //       />
                  //     </g>
                  //     <defs>
                  //       <clipPath id="clip0_64_129">
                  //         <rect width="24" height="24" fill="white" />
                  //       </clipPath>
                  //     </defs>
                  //   </svg>
                  //   </Tooltip>
                  // }
                  value={
                    query.length == 0 && query != question ? question : query
                  }
                  onChange={handleInputChange}
                />
              </Col>
              <Col lg={2} md={5} xs={24} sm={24}>
                <button
                  type="button"
                  className="btn4"
                  onClick={handleSuffixClick}
                >
                  Submit
                </button>
              </Col>
            </Row> */}
            {/* <Search
            className="custom-large-search"
              placeholder="input search text"
              allowClear
              enterButton="Search"
              size="large"
              // onSearch={}
            /> */}
            <Search
              className="custom-large-search"
              placeholder="input search text"
              allowClear
              onSearch={handleSuffixClick}
              onChange={handleInputChange}
              value={
                // query.length === 0 && query !== question ? question : query
                demoData
              }
              onKeyDown={(e) => {
                
                if (e.key === "Enter") {
                  e.preventDefault();
                }
              }}
            />
          </Col>
        </Row>
        <Row
          gutter={[24]}
          style={{
            justifyContent: "center",
            alignItems: "start",
            paddingTop: "2rem",
            margin: 0,
          }}
        >
          <Col lg={1} md={1} xs={24} sm={24}>
            <img src={Avatar} />
          </Col>
          <Col lg={14} md={14} xs={24} sm={24}>           
            <Card className="card">
              {data.map((item, key) => {
                return (
                  <>
                    <p key={key} className="para">
                      {item.answer}
                    </p>
                    <p
                      style={{
                        color: "blue",
                        fontSize: "17px",
                        fontStyle: "italic",
                      }}
                    >
                      {item.static}
                    </p>
                  </>
                );
              })}
              <p
                style={{
                  textAlign: "right",
                  fontSize: "17px",
                }}
              >
                Was this helpful ?
              </p>
              <Row style={{ justifyContent: "space-between" }}>
                <Col>
                  <CopyOutlined
                    onClick={() => {
                      handleCopyClick(data);
                    }}
                    style={{ fontSize: "24px", color: "#BDBDBD" }}
                  />
                </Col>
                <Col>
                  <div
                    style={{
                      display: "flex",
                      gap: "28px",
                      marginRight: "1rem",
                    }}
                  >
                    <LikeOutlined
                      onClick={() => {
                        handleLikeClick1(false);
                        likeDislike(true);
                      }}
                      style={{ fontSize: "24px", color: likeColor1 }}
                    />
                    <DislikeOutlined
                      onClick={() => {
                        handleDislikeClick1(true);
                        likeDislike(false);
                      }}
                      style={{ fontSize: "24px", color: dislikeColor1 }}
                    />
                  </div>
                </Col>
              </Row>
              {showReGenerateRes && regerateData.length == 0 ? (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <button className="regerate-res" onClick={reGenerateRes}>
                    Regenerate Response
                  </button>
                </div>
              ) : null}
            </Card>
            {regerateData.length == 0 ? null : (
              <Card className="card">
                {regerateData.map((item, key) => {
                  return (
                    <>
                      <p key={key} className="para">
                        {item.answer}
                      </p>
                      <p
                        style={{
                          color: "blue",
                          fontSize: "17px",
                          fontStyle: "italic",
                        }}
                      >
                        {item.static}
                      </p>
                    </>
                  );
                })}
                 <p
                style={{
                  textAlign: "right",
                  fontSize: "17px",
                }}
              >
                Was this helpful ?
              </p>
                <Row style={{ justifyContent: "space-between" }}>
                  <Col>
                    <CopyOutlined
                      onClick={() => {
                        handleCopyClick(regerateData);
                      }}
                      style={{ fontSize: "24px", color: "#BDBDBD" }}
                    />
                  </Col>
                  <Col>
                    <div
                      style={{
                        display: "flex",
                        gap: "28px",
                        marginRight: "1rem",
                      }}
                    >
                      <LikeOutlined
                        onClick={() => {
                          handleLikeClick2();
                          likeDislike(true);
                        }}
                        style={{ fontSize: "24px", color: likeColor2 }}
                      />
                      <DislikeOutlined
                        onClick={() => {
                          handleDislikeClick2();
                          likeDislike(false);
                        }}
                        style={{ fontSize: "24px", color: dislikeColor2 }}
                      />
                    </div>
                  </Col>
                </Row>
              </Card>
            )}
          </Col>
        </Row>
       { consultbtn && <Row style={{justifyContent: "center"}}>
          <Col>
          <a  href="https://pages.razorpay.com/consult-lawyer" target="_blank" >
            <button className="consult-btn" >
              Consult an Expert
            </button>
            </a>
          </Col>
        </Row>
                      }
        <div style={{ paddingTop: "3rem" }}>
          <Footer navPosition={true} />
        </div>
        {/* <Row
          gutter={[18]}
          style={{ justifyContent: "center", margin: 0, paddingBottom: "3rem" }}
        >
          <Col lg={12} md={12} xs={24} sm={24}>
            <Input
              className="search-item"
              value="Document for registering a company"
            />
          </Col>
          <Col lg={2} md={5} xs={24} sm={24}>
            <button className="btn3">Submit</button>
          </Col>
        </Row> */}
      </Spin>
    </>
  );
};

export default SearchResults;

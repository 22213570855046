import React, { useEffect, useState } from "react";
import { Row, Col, Input, Form } from "antd";
import { Link } from "react-router-dom";

import { useNavigate } from "react-router-dom";
/* Component */
import Footer from "./Footer";

import { API_BASE_URL } from "../config/config";
import { APIUrlList } from "../functional/api_url";
import commonFunction from "../functional/common_function";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

/* Image */
import fullLogo from "../img/full-logo.png";
import icon from "../img/icon.png";
import formulawIcon from "../img/fomulaw-icon.png";

/* styles */
import "../Styles/Login.css";

const Login = ({ setOtpShow, otpShow, setOtp, otp }) => {
  const navigate = useNavigate();
  const notify = (message) => toast(message);
  const [mobLength, setMobLength] = useState(false);
  const [demo, setDemo] = useState(null);
  const [mobleNo, setMobileNo] = useState("");
  const [checkNo, setCheckNo] = useState(false);
  const [onbtnCheck, setOnBtnCheck] = useState(false);
const [sendBtnShow,setSendBtnShow]=useState(false);

    useEffect(()=>{
      setTimeout(()=>{
        sessionStorage.removeItem("starter")
      },1000)
    },[])

  const onFinish = (values) => {
    
    const url = API_BASE_URL + APIUrlList.UserLogin;
    const payload = {
      phoneNo: `${mobleNo}`,
      // Type: "web",
    };

    if (mobleNo.length < 10) {
      setCheckNo(true);
      setOnBtnCheck(true);
   
    } else {
      setSendBtnShow(true);
      commonFunction
        ._sendAPIRequest(payload, url, "post", {
          "Content-Type": "application/json",
        })
        .then((response) => {
          if (response.data.status) {
            setTimeout(() => {
              navigate("/otp");
            }, 2000);

            localStorage.setItem("mobile",mobleNo)
            localStorage.setItem("userHasCompletedOTP",true);
            setTimeout(() => {
              setOtpShow(false);
            }, 6000);
            setOtpShow(true);
            setOtp(response.data.otp);
            notify(response.data.message);
          } else {
            commonFunction._showAlertError(response.data.message);
            localStorage.setItem("islogin", false);
            localStorage.setItem("userHasCompletedOTP",false);
          }
        })
        .catch((error) => {
          commonFunction._showAlertError(error?.response?.data?.message);
          console.log(error?.response?.data);
          localStorage.setItem("islogin", false);
          localStorage.setItem("userHasCompletedOTP",false);
        });
    }
  };

  const handleInput = (event) => {
    const value = event.target.value;
    console.log('value :', value.length);

    if (!isNaN(value)&& !value.includes(" ") && !value.includes("."))  {
      if (value.length <= 10) {
        setDemo(value);
        setMobileNo(value);
      }
      if(value.length==10){
        setMobLength(true);
      }else{
        setMobLength(false);
      }
    }
    
  };




  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Row
        gutter={[12]}
        className="mob-screen"
        style={{
          margin: "10px 20px",
          display: "flex",
          justifyContent: "space-evenly",
          alignItems: "center",
        }}
      >
        <Col>
          <img width={"48px"} height={"51px"} src={icon} />
        </Col>
        <Col>
          <img
            width={"60px"}
            height={"40px"}
            src={formulawIcon}
            className="logo"
          />
        </Col>
      </Row>
      <Row className="login-pageHight">
        <Col lg={12} md={24} xs={24} sm={24} className="rightside-part">
          <Row style={{ marginTop: "200px" }}>
            <Col lg={24} md={24} xs={24} sm={24}>
              <div className="logo-container">
                <img width={"150"} height={"150"} src={fullLogo} />
              </div>

              <p className="text-title">
                Access to Legal,{" "}
                <span className="hightlight-text">Made Simple...</span>
              </p>
            </Col>
          </Row>
        </Col>

        <Col lg={12} md={24} xs={24} sm={24} className="form-design">
          <Form
            name="basic"
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
            // onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Row className="card-container">
              <Col lg={15} md={15} xs={24} sm={24}>
                <div className="login-card">
                  <h3 className="primary-heading">Login</h3>
                  <Row gutter={[8]}>
                    <Col lg={6} md={6} xs={6} sm={6}>
                      <Input className="mobile-type" value={"+91"} readOnly />
                    </Col>
                    <Col lg={18} md={18} xs={18} sm={18}>
                      {/* <Form.Item
                        name="mobileno" 
                        rules={[
                          {
                            required: true,
                            message: "Please check  Mobile Number!",
                            pattern: new RegExp("^[0-9]{10}$"),
                          },
                        ]}
                      > */}
                      {/* <Input
                          type="text"
                          className="mobile-field"
                          placeholder="Enter your Mobile Number"
                          onChange={(e) => {
                            e.target.value.length == 10
                              ? setMobLength(true)
                              : setMobLength(false);
                          }}
                        /> */}

                      <Input
                        maxLength={10}
                        name="mobileno"
                        type="tel"
                        className="mobile-field placeholder-color-change"
                        placeholder=" Mobile Number"
                        // status={mobleNo.length && checkNo < 10 || onbtnCheck  ? "error" : null}
                        // status={mobleNo.length <10 && checkNo ? "error" : null}
                        // placeholder={
                        //   onbtnCheck? (
                        //  " Enter your valid Mobile Number"

                        //   ) : (
                        //     " Mobile Number"
                        //   )
                        // }
                        value={demo}
                        onChange={(event) => {
                          // event.target.value.length == 10
                          //   ? setMobLength(true)
                          //   : setMobLength(false);
                          handleInput(event);
                        }}
                      />
                      {mobleNo.length < 10 && checkNo ? (
                        <p
                          style={{
                            color: "red",
                            marginLeft: "20px",
                            marginTop: "8px",
                            marginBottom: "0px",
                          }}
                        >
                          Enter Your Valid Mobile Number
                        </p>
                      ) : null}
                      {/* </Form.Item> */}
                    </Col>
                  </Row>

                  <Row gutter={[34]} className="btn-container">
                    <Col>
                      <button
                        type="submit"
                        className="send-btn "
                        disabled={sendBtnShow}
                        style={{
                          background: mobLength
                            ? "linear-gradient(270deg, #5be0ff 0%, #0062e9 100%)"
                            : null,
                          color: mobLength ? "#fff" : null,
                        }}
                      >
                        Send OTP
                      </button>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
      <Footer />
    </>
  );
};

export default Login;

import React from "react";

import AppStore from "../img/app-store.png";
import GooglePlay from "../img/google-play.png";
import "../Styles/Footer.css";

const Footer = ({navPosition}) => {
  return (
    /* Footer */

    <div className={ !navPosition? "footer-container" :"footer-container-for-result" }>
      <div className="app-icons">
        <a href='https://play.google.com/store/apps/details?id=com.formulaw' target="_blank">
        <img src={GooglePlay} />
        </a>
        <a href='https://apps.apple.com/app/formulaw-consult-lawyer-online/id6459364169' target="_blank">
        <img src={AppStore} />
        </a>
      </div>
      <ul className="footer-links">
        <li>Homepage</li>
        <li>Terms of Use</li>
        <li>Privacy Policy</li>
      </ul>
    </div>
  );
};

export default Footer;

import React, { useState, useEffect } from "react";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import Login from "./Pages/Login";
import OtpPage from "./Pages/OtpPage";
import Navbar from "./Pages/Navbar";
import SearchResults from "./Pages/SearchResults";
import Nav from "./Pages/Nav";
import SearchHistory from "./Pages/SearchHistory";

import SearchPage from "./Pages/SearchPage";

function App() {
  const [otpShow, setOtpShow] = useState(false);
  const [otp, setOtp] = useState(null);
  const [query, setQuery] = useState("");
  const [type, setType] = useState("");
  const accessToken = localStorage.getItem("accessToken");
  const navigate = useNavigate();
  let { pathname } = useLocation();
  const auth =JSON.parse( localStorage.getItem("userIsLogin")) || null;
  const userHasCompletedOTP =JSON.parse(localStorage.getItem("userHasCompletedOTP")) || null;
  const searchauth = sessionStorage.getItem("starter");



  const goToSearchPage = () => navigate("/")
  const goToLoginPage = () => navigate("/login");
    const goToHomePage = () => navigate("/search");
    const goToOTPPage = () => navigate("/otp");

    useEffect(() => {
    
      if(searchauth){
        if(pathname === "/" || pathname === "/otp" ){
          goToLoginPage();
         }  
      }else{
        if (auth) {
          if(pathname === "/" || pathname === "/otp" ){
            goToHomePage();
          }   
         }else{
             if(userHasCompletedOTP){
               goToOTPPage();
             }else{
               goToSearchPage();
             }
         }
      }
      
    }, [navigate]);

  // useEffect(() => {
    
  //   if (auth) {
  //    if(pathname === "/" || pathname === "/otp"){
  //       goToHomePage();
  //    }   
  //   }else{
  //       if(userHasCompletedOTP){
  //         goToOTPPage();
  //       }else{
  //         goToLoginPage();
  //       }
  //   }
  // }, [navigate]);


  

  return (
    <Routes>
      <Route
        path="/"
        element={
          <SearchPage/>
        }
      />
      <Route
        path="/login"
        element={
          <Login
            setOtpShow={setOtpShow}
            otpShow={otpShow}
            setOtp={setOtp}
            otp={otp}
          />
        }
      />
      <Route
        path="/otp"
        element={
          <OtpPage
            setOtpShow={setOtpShow}
            otpShow={otpShow}
            setOtp={setOtp}
            otp={otp}
          />
        }
      />

      <Route path="/nav" element={<Navbar />} />

      <Route
        path="/nav-result/:question/"
        element={
          <SearchResults
            setQuery={setQuery}
            query={query}
            setType={setType}
            type={type}
          />
        }
      />
      <Route
        path="/search"
        element={
          <Nav
            setQuery={setQuery}
            query={query}
            setType={setType}
            type={type}
          />
        }
      />
      <Route path="/search-history" element={<SearchHistory />} />

      {/* <Route path="*" element={<FrontEndNoMatch />} /> */}
    </Routes>
  );
}

export default App;

import axios from 'axios'

import Swal from "sweetalert2";

function _sendAPIRequest(data, url, action, headers = {}) {
    
    if (action === 'post') {


        return axios
            .post(url, data,headers)
            .then(response => {
                return response;
            })
            .catch(error => {
                return error.response;
            })
    }  else if (action === 'put') {

        return axios
            .put(url, data, headers)
            .then(response => {
                return response;
            })
            .catch(error => {
                return error.response;
            })
    }
    else if (action === 'patch') {
        console.log('headers :', headers);
        return axios
            .patch(url, data, headers)
            .then(response => {
                return response;
            })
            .catch(error => {
                return error.response;
            })
    }
     else if (action === 'get') {
       
        return axios
            .get(url)
            .then(response => {
                return response;
            })
            .catch(error => {
                return error.response;
            })
    } else {
        return axios
            .delete(url,{
                headers:headers
            })
            .then(response => {
                return response;
            })
            .catch(error => {
                return error.response;
            })
    }
}

function _sendAPIRequestForGet(url, action, headers = {}) {
    if (action === 'post') {

        return axios
            .post(url, headers)
            .then(response => {
                return response;
            })
            .catch(error => {
                return error.response;
            })
    } else if (action === 'get') {
        return axios
            .get(url,headers)
            .then(response => {
                return response;
            })
            .catch(error => {
                return error.response;
            })
    }
    else if (action === 'delete') {
       
        return axios
            .delete(url,headers)
            .then(response => {
                return response;
            })
            .catch(error => {
                return error.response;
            })
    }
    else {
        return axios
            .delete(url,{
                headers:headers
            })
            .then(response => {
                return response;
            })
            .catch(error => {
                return error.response;
            })
    }
}
export function _showAlertError(text, title = "") {
    return Swal.fire({
        icon: 'error',
        title: text,
        text: title,
        confirmButtonColor: "#28a745",
        timer: 3000,
        timerProgressBar: true,
    });
}
export function _showAlertSuccess(text, title = "") {
    return Swal.fire({
        icon: 'success',
        title: text,
        text: title,
        confirmButtonColor: "#28a745",
        timer: 3000,
        timerProgressBar: true,
    });
}


export default {
    _sendAPIRequest,
    _showAlertError,
    _showAlertSuccess,
    _sendAPIRequestForGet

}

import React, { useEffect, useRef, useState } from "react";
import { BiMenuAltRight } from "react-icons/bi";
import GooglePlay from "../img/nav-logo.png";
import { RiseOutlined } from "@ant-design/icons";
import { Link, useNavigate } from "react-router-dom";

import { API_BASE_URL } from "../config/config";
import { APIUrlList } from "../functional/api_url";
import commonFunction from "../functional/common_function";
import { Input, Row, Col, Select } from "antd";
import "../Styles/Navbar.css";
import Footer from "./Footer";
const Nav = ({ setQuery, query, setType }) => {

  const menuRef = useRef(null);
  const navigate = useNavigate();
  const [menuOpened, setMenuOpened] = useState(false);
  // const [query, setQuery] = useState("");

  const [dropDownData, setDropDownData] = useState([]);

  const [errorStyle, setErrorStyle] = useState(false);

  const regex = /^(?!\s).*/;


  const goToLoginPage = () => {
    localStorage.clear()
  };

  const getMenuStyles = (menuOpened) => {
    if (document.documentElement.clientWidth <= 770) {
      return {
        right: !menuOpened && "-100%",
        display: !menuOpened ? "none" : null,
      };
    }
  };

  const FsoOptions = [];

  if (dropDownData != null) {
    dropDownData.map((data) =>
      FsoOptions.push({
        label: data.query,
        value: data.query,
      })
    );
  }

  /*  get all query data   */

  const getSearchQuery = (searchQuery) => {
    /* fetched data from localstoarage */

    let getAuthToken = JSON.parse(localStorage.getItem("accessToken"));
    localStorage.setItem("query", searchQuery);

    const url =
      API_BASE_URL + APIUrlList.SearchQuery + `?searchWord=${searchQuery}`;
    const config = {
      headers: { Authorization: `Bearer ${getAuthToken}` },
    };

    commonFunction
      ._sendAPIRequestForGet(url, "get", config)
      .then((response) => {
        setDropDownData(response.data.data);
      })
      .catch((error) => {
        commonFunction._showAlertError(error?.response?.data?.message);
      });
  };

  
  const OnSubmitSearchQuery = () => {
    setType("btn");
    
    const isMatched = dropDownData.some(
      (queryObj) => queryObj.query.toLowerCase() === query.toLowerCase()
    );
  
    // localStorage.setItem("query",query);
    localStorage.setItem("type", isMatched ? "Search" : "AI");

    if (query.length == 0) {
  
    } else {
      setTimeout(() => {
        navigate(
          `/nav-result/${encodeURIComponent(query)}`
        );
      }, 1000);
    }
  };

 
  const errorCheck = () => {
    
    if (query.length === 0) {
      
      setErrorStyle(true);
    } else {
      setErrorStyle(false);
    }
  };

  const userSearch = (selectQuery) => {
    
    setType("select");
    setQuery(selectQuery);

    localStorage.setItem("query", selectQuery);
    setTimeout(() => {
      // navigate(`/nav-result/${encodeURIComponent(selectQuery)}/select`, {
      navigate(`/nav-result/${encodeURIComponent(selectQuery)}`, {
      });
    }, 1000);
  };

  useEffect(()=>{
    setQuery("");
  },[])

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          margin: "1.6rem",
          alignItems: "center",
        }}
      >
        <img
          className="hide-formulaw"
          src={GooglePlay}
          width={"200px"}
          height={"52px"}
        />
        <ul // Use motion.ul instead of ul
          className="menu"
          ref={menuRef}
          style={getMenuStyles(menuOpened)}
        >
          <li>
            <Link className="btn1" to="/search-history">
              History
            </Link>
          </li>
          <li>
            <Link className="btn2" to="/" onClick={goToLoginPage} >
              logout
            </Link>
          </li>
        </ul>
        <div
          className="menuIcon"
          onClick={() => setMenuOpened((prev) => !prev)}
        >
          <BiMenuAltRight size={30} />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "5rem",
          marginBottom: "3rem",
        }}
      >
        <img
          className="formulaw-icon"
          src={GooglePlay}
          width={"200px"}
          height={"52px"}
        />
      </div>
      <Row gutter={[18]} style={{ justifyContent: "center", margin: 0 }}>
        <Col lg={10} md={10} xs={24} sm={24}>
          <Select
            showSearch
            optionFilterProp="children"
            size="large"
            onChange={userSearch}
            status={errorStyle ? "error" : null}
            onInput={(e) =>  regex.test(e.target.value) ? setQuery(e.target.value): null  }
            // placeholder={ errorStyle?"please enter your query":`Document for registering a company`}
            placeholder={
              errorStyle ? (
                <span style={{ color: 'red' }}>Please enter your query</span>
              ) : (
                'Enter your query, be as detailed as possible'
              )
            }
            style={{
              width: "100%",
              
            }}
            onSearch={getSearchQuery}
            options={FsoOptions}
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
           
            onKeyUp={(e) => {
              
              errorCheck();
              if (e.key === "Enter") {
                e.preventDefault();
                OnSubmitSearchQuery();
              }
            }}
          />
        </Col>
        <Col lg={2} md={5} xs={24} sm={24}>
          <button
            type="button"
            className="btn3"
            onClick={()=>{OnSubmitSearchQuery();errorCheck();}}
          >
            Submit
          </button>
        </Col>
      </Row>
      <Row
        gutter={[18]}
        style={{ justifyContent: "center", margin: 0, paddingTop: "2.5rem" }}
      >
        <Col lg={6} md={6} xs={24} sm={24}>
          {/* <p className="link">
            My passport is stolen <RiseOutlined className="up-icon" />{" "}
          </p> */}

          {/* <p className="link">How it works?</p> */}
        </Col>
      </Row>
      <Footer />
    </>
  );
};

export default Nav;

import React, { useState, useEffect } from "react";
import { Row, Col, Input } from "antd";
import { Link, useNavigate,useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
/* API Common */
import { API_BASE_URL } from "../config/config";
import { APIUrlList } from "../functional/api_url";
import commonFunction from "../functional/common_function";

/* Image */
import fullLogo from "../img/full-logo.png";
/* Component */
import Footer from "./Footer";
/* styles */
import "../Styles/Login.css";

const OtpPage = ({
  setOtpShow,
  setOtp,
  otpShow,
  otp,
}) => {



  const navigate = useNavigate();
  const notify = (message) => toast(message);
  const location = useLocation();

  // const { mobileno } = location.state ;

  let mobileno=localStorage.getItem("mobile")|| null;

  const [otpValues, setOtpValues] = useState(Array(4).fill(""));
 

  const [errorStyle,setErrorStyle]=useState(false);
  const [timer, setTimer] = useState(30);

  let userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const inputRefs = Array.from({ length: 4 }, () => React.createRef());


  const handleInput = (index, event) => {
    const value = event.target.value;

    if(otpValues.join("").length==3){
      setErrorStyle(false);
    }
    if (!isNaN(value) && !value.includes(" ")) {
      if (value.length <= 1) {
        const newOtpValues = [...otpValues];
        newOtpValues[index] = value;
        setOtpValues(newOtpValues);

        if (value.length === 1 && index < inputRefs.length - 1) {
          inputRefs[index + 1].current.focus();
        }
       
      }
     
    }
  };


useEffect(()=>{
 
    localStorage.setItem("userHasCompletedOTP",false);

},[])

  const handleKeyDown = (index, event) => {
    if (
      event.key === "Backspace" &&
      index > 0 &&
      otpValues[index].length === 0
    ) {
      // Backspace was pressed and the input is empty, so move focus to the previous input
      inputRefs[index - 1].current.focus();
    }
    if (event.key === "Enter") {
                 
      event.preventDefault();
      submitOtp()
    }
  };

  const submitOtp = () => {
    const url = API_BASE_URL + APIUrlList.VerifyOtp;
    const resultString = otpValues.join("");


    const payload = {
      phoneNo: mobileno,
      otp: resultString,
      type:"web"
    };

    commonFunction
      ._sendAPIRequest(payload, url, "post", {
        "Content-Type": "application/json",
      })
      .then((response) => {
       if(resultString.length<3){
        setErrorStyle(true);
       }else{
        if (response.data.status) {
          setTimeout(() => {
            navigate("/search");
          }, 2500);

          localStorage.setItem("userInfo", JSON.stringify(response.data.data));
          localStorage.setItem(
            "accessToken",
            JSON.stringify(response.data.access_token)
          );
          localStorage.setItem("userIsLogin", true);
         

          commonFunction._showAlertSuccess(response.data.message);
        } else {
          commonFunction._showAlertError(response.data.message);
          localStorage.setItem("userIsLogin", false);
         
        }
       }
      })
      .catch((error) => {
        commonFunction._showAlertError(error?.response?.data?.message);
        console.log(error?.response?.data);
        localStorage.setItem("userIsLogin", false);
        
      });
  };

  const resetOtp = () => {
    const url = API_BASE_URL + APIUrlList.UserLogin;
    const payload = {
      phoneNo: mobileno,
      // Type: "web",
    };
    commonFunction
      ._sendAPIRequest(payload, url, "post", {
        "Content-Type": "application/json",
      })
      .then((response) => {
        if (response.data.status) {
          setOtpShow(true);
          setOtp(response.data.otp);
          notify(response.data.message);
          setTimeout(()=>{
            setOtpShow(false);
          },9000)
          setTimer(30);
        
        } else {
          commonFunction._showAlertError(response.data.message);
       
        }
      })
      .catch((error) => {
        commonFunction._showAlertError(error?.response?.data?.message);
        console.log(error?.response?.data);

      });
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setTimer((prevTimer) => (prevTimer > 0 ? prevTimer - 1 : 0));
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <>
     <ToastContainer
      position="top-center"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="light"
      />
      <Row style={{ height: "100vh" }}>
        <Col lg={12} md={24} xs={24} sm={24} className="rightside-part">
          <Row style={{ marginTop: "200px" }}>
            <Col lg={24} md={24} xs={24} sm={24}>
              <div className="logo-container">
                <img
                  width={"150px"}
                  height={"150px"}
                  src={fullLogo}
                 
                />
              </div>

              <p className="text-title">
                Access to Legal,{" "}
                <span className="hightlight-text">Made Simple.</span>
              </p>
            </Col>
          </Row>
        </Col>
        <Col lg={12} md={24} xs={24} sm={24} style={{ marginTop: "120px" }}>
          <Row className="card-container">
            <Col lg={15} md={15} xs={24} sm={24}>
              <div className="login-card">
                <Link to="/">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="30"
                    viewBox="0 0 50 34"
                    fill="none"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M0.707044 16.2542L0.141357 16.8199L0.707044 17.3856L16.4188 33.0973L17.5501 31.9659L3.34825 17.764H49.2728V16.164H3.05996L17.5501 1.67385L16.4188 0.54248L0.707044 16.2542Z"
                      fill="#718096"
                    />
                  </svg>
                </Link>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <h3 className="primary-heading">OTP</h3>
                  {/* {otpShow && (
                    <p
                      style={{
                        background: "coral",
                        color: "#fff",
                        padding: "12px 16px",
                        borderRadius: "35px",
                        border: "none",
                      }}
                    >
                      {otp}
                    </p>
                  )} */}
                </div>
                <p className="secondary-heading">
                  Enter 4 digit OTP Sent to <br /> <b>+91 {mobileno}</b>
                </p>

                <Row gutter={[8]}>
                  {inputRefs.map((ref, index) => (
                    <Col lg={6} md={6} xs={6} sm={6} key={index}>
                      <Input
                        maxLength={1}
                        type="tel"
                        className="mobile-type-otp"
                        ref={ref}
                        value={otpValues[index]}
                        onChange={(event) => handleInput(index, event)}
                        onKeyDown={(event) => handleKeyDown(index, event)}
                      />
                      
                    </Col>
                  ))}
                </Row>
                <Row>
                  <Col>
                  {errorStyle ? (
                        <p
                          style={{
                            color: "red",
                            marginLeft: "5px",
                            marginTop: "10px",
                            marginBottom: "0px",
                            fontSize:"0.9rem"
                          }}
                        >
                          Please Enter Valid Otp
                        </p>
                      ) : null}
                  </Col>
                </Row>

                <Row gutter={[34]} className="btn-container">
                  <Col>
                    <button   onClick={() => submitOtp()} className="send-btn" style={{background:otpValues.join("").length==4?"linear-gradient(270deg, #5be0ff 0%, #0062e9 100%)":null ,color:otpValues.join("").length==4?'#fff':null}} >
                      Submit
                    </button>
                  </Col>
                  <Col>
                    {timer > 0 ? (
                     <button
                     className={`timer-btn `}
                   >
                     {timer}s
                   </button>
                    ) : (
                      <button
                        // disabled={timer > 0}
                        onClick={() => resetOtp()}
                        className={`signUp-btn `}
                      >
                        Resend OTP
                      </button>
                    )}
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      <Footer />
    </>
  );
};

export default OtpPage;

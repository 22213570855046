import React, { useEffect, useState } from "react";
import { Row, Col, Card, Table, Spin, Pagination } from "antd";
import moment from "moment";
import { API_BASE_URL } from "../config/config";
import { APIUrlList } from "../functional/api_url";
import commonFunction from "../functional/common_function";
import GooglePlay from "../img/nav-logo.png";
const SearchHistory = () => {
  const [data, setData] = useState([]);
  const [totalCount, setTotalCount] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [showSpinner, setShowSpinner] = useState(false);

  const handleChangePage = (page) => {
    setCurrentPage(page);
  };

  const columns = [

    {
      dataIndex: "query",
      title: "Query",
      sort: true,
      width: "150px",
    },
    {
      dataIndex: "answer",
      title: "Answer",
      sort: true,
    },
    {
      dataIndex: "status",
      title: "Status",
      sort: true,
    },
    {
      dataIndex: "type",
      title: "type",
      sort: true,
    },

    {
      dataIndex: "createdAt",
      title: "Date And Time",
      sort: true,
      width: "150px",
      render: (createdAt) =>
        moment(createdAt).utc().format("MMMM Do YYYY, h:mm:ss a"),
    },
  ];

  const searchHistory = () => {
    /* fetched data from localstoarage */
    setShowSpinner(true);
    let getAuthToken = JSON.parse(localStorage.getItem("accessToken"));
    let userInfo = JSON.parse(localStorage.getItem("userInfo"));

    const url =
      API_BASE_URL +
      APIUrlList.SearchHistory +
      `?phone_number=${userInfo.phoneNo}&page=${currentPage}&limit=10`;
    const config = {
      headers: { Authorization: `Bearer ${getAuthToken}` },
    };

    commonFunction
      ._sendAPIRequestForGet(url, "get", config)
      .then((response) => {
        setData(response.data.data);
        setTotalCount(response.data.count);
        setShowSpinner(false);
      })
      .catch((error) => {
        commonFunction._showAlertError(error?.response?.data?.message);
        setShowSpinner(false);
      });
  };

  useEffect(() => {
    searchHistory();
  }, [currentPage]);

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          margin: "1.6rem",
          alignItems: "center",
        }}
      >
        <img src={GooglePlay} width={"200px"} height={"52px"} />
        {/* <ul // Use motion.ul instead of ul
          className="menu"
          ref={menuRef}
          style={getMenuStyles(menuOpened)}
        >
          <li>
            <Link className="btn1" to="/">
              Log in
            </Link>
          </li>
          <li>
            <Link className="btn2" to="/">
              Sign up
            </Link>
          </li>
        </ul> */}
      </div>

      <Spin spinning={showSpinner}>
        <Row style={{ justifyContent: "center" }}>
          <Col lg={18} md={18} xs={24} sm={24}>
            <Card className="card">
              <h3
                style={{
                  color: "#0d5191",
                  fontSize: "1.4rem",
                  fontWeight: "700",
                }}
              >
                Search History
              </h3>
              <div style={{ marginTop: "1.2rem" }}>
                <div className="table-responsive">
                  {" "}
                  {/* Add a wrapper with responsive class */}
                  <Table
                    className="table-empty"
                    columns={columns}
                    dataSource={data}
                    pagination={false}
                  />
                  {/* {data.map((ele, key) => {
                    return (
                      <Card
                        key={key}
                        style={{ marginBottom: "1rem" }}
                        className="card-show"
                      >
                        <div
                          style={{
                            display: "flex",
                            
                            alignItems: "center",
                          }}
                        >
                          <h5>Query :</h5>
                          <span> {ele.query}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                           
                            alignItems: "center",
                          }}
                        >
                          <h5>Answer :</h5>
                          <span> {ele.answer}</span>
                        </div>
                        
                        
                      </Card>
                    );
                  })} */}
                </div>
                <Pagination
                  defaultCurrent={currentPage}
                  showSizeChanger={false}
                  total={totalCount}
                  onChange={(pg) => handleChangePage(pg)}
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginTop: "10px",
                  }}
                />
              </div>
            </Card>
          </Col>
        </Row>
      </Spin>
    </>
  );
};

export default SearchHistory;

var API_BASE_URL;


// console.log('process.env.REACT_APP_YOUR_BASE_URL  :',process.env.REACT_APP_YOUR_BASE_URL  );
// if ( process.env.REACT_APP_YOUR_BASE_URL === 'dev') {
//    API_BASE_URL = 'https://api.formulaw.io/local/api/';
// } else if (process.env.REACT_APP_YOUR_BASE_URL === 'live') {
//     API_BASE_URL = 'https://api.formulaw.io/dev/api/';
// }

// for live 
API_BASE_URL = 'https://api.formulaw.io/dev/api/';


// for dev
// API_BASE_URL = 'https://api.formulaw.io/local/api/';

export {
    API_BASE_URL,
};
